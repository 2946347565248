import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import moment from "moment"
import "moment-timezone"
window.moment = moment;
import "channels"
import "chartkick/chart.js"

global.Rails = Rails;

Rails.start()
ActiveStorage.start()

var jQuery = require("jquery")
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

import "jquery-ui"
// import "bootstrap"
import "summernote"
import "select2"
import "perfect-scrollbar"
import ahoy from "ahoy.js"
